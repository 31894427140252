<template>
  <a
    :class="[
      'bg-black-600 font-mierb hover:bg-black-500 flex h-full w-full cursor-pointer items-center justify-between px-8 py-6 transition-all ease-in-out sm:px-6 xl:flex-row',
      buttonText ? 'flex-col' : 'flex-row',
    ]"
    @click="handleNavigation"
  >
    <div :class="['flex w-full flex-col-reverse xl:flex-row']">
      <div
        v-if="dateHeadline"
        class="flex xl:mr-9 xl:flex-col xl:justify-between"
      >
        <Headline
          :text="dateHeadline"
          variant="bodyXLarge"
          headline-class="tracking-wider text-white font-semibold"
        />

        <div
          v-if="subTitleDateIcon || subTitle"
          class="text-bodyLarge text-black-200 ml-2 flex font-medium leading-6 xl:ml-0"
        >
          <MaterialIcon
            v-if="subTitleDateIcon"
            :name="subTitleDateIcon"
            size="small"
            icon-color="white"
          />
          <div v-if="subTitleDate" :class="{ 'ml-3': subTitleDateIcon }">
            {{ subTitleDate }}
          </div>
        </div>
      </div>
      <div
        class="flex flex-col xl:justify-between"
        :class="{ 'xl:mr-4': tags?.data[1]?.attributes?.text }"
      >
        <div class="flex items-center text-white">
          <Headline
            v-if="headlineHighlighted"
            :text="headlineHighlighted"
            :variant="headlineHighlightedVariant"
            headline-class="line-clamp-1 font-semibold"
          />
          &nbsp;
          <Headline
            v-if="headline"
            :text="headline"
            :variant="headlineHighlightedVariant"
            headline-class="line-clamp-1 font-medium"
          />
        </div>
        <div
          v-if="subTitleIcon || subTitle"
          class="items-unset text-bodyXLarge text-black-200 flex pb-2 pt-3 font-medium xl:py-0 xl:leading-6"
        >
          <MaterialIcon
            v-if="subTitleIcon"
            :name="subTitleIcon"
            size="small"
            icon-color="white"
          />
          <div
            v-if="subTitle"
            :class="[
              subTitleIcon && 'ml-2',
              'text-bodyLarge line-clamp-1',
              subTitleClassName && subTitleClassName,
            ]"
          >
            {{ subTitle }}
          </div>
        </div>
      </div>
    </div>
    <div
      :class="[
        'mr-auto flex w-full flex-row items-center pl-0 xl:mr-0 xl:h-full xl:w-max xl:items-center xl:pl-6',
        buttonText || buttonIconName
          ? 'mt-2 w-full items-baseline xl:mt-0'
          : 'w-max',
        !tags?.data.length ? 'w-full justify-end' : 'justify-between',
        'xl:justify-start',
      ]"
    >
      <div
        v-if="
          tags?.data[0]?.attributes?.text || tags?.data[1]?.attributes?.text
        "
        class="grid-cols-wrap grid grid-flow-col items-center justify-end gap-x-3 pr-4 xl:w-auto"
      >
        <Tags
          v-if="tags?.data[0]?.attributes"
          v-bind="tags?.data[0]?.attributes"
        />
        <!-- Currently, this section is commented out due to a UI issue. -->
        <!-- <Tags
          v-if="tags?.data[1]?.attributes"
          v-bind="tags?.data[1]?.attributes"
        /> -->
      </div>
      <div
        v-if="buttonText || buttonIconName"
        :class="[
          buttonVariant === 'ghost' && buttonText && 'text-black-100',
          buttonCustomStyle?.includes('width: 100%;') && 'w-full',
        ]"
      >
        <StrapiButton
          :text="dynamicText"
          :variant="buttonVariant"
          :icon="dynamicIconName"
          :underline="isButtonUnderline"
          :disabled="isButtonDisabled"
          :custom-style="buttonCustomStyle"
          :file="materialFile?.data?.attributes"
          icon-size="large"
          :icon-color="flagColorSucesfuly ? '#0DC761' : undefined"
          class="hidden lg:block"
        />
      </div>
    </div>
  </a>
</template>
<script lang="ts" setup>
import { PropType } from "vue";
import { tagBackground, tagColors, tagVariants } from "./types";
import Headline from "~/components/Headline/Headline.vue";
import Tags from "~/components/Tags/Tags.vue";
import StrapiButton from "~/components/StrapiButton/StrapiButton.vue";
import MaterialIcon from "~/components/Icon/MaterialIcon.vue";

const props = defineProps({
  headlineHighlighted: {
    type: String,
    default: undefined,
  },
  headlineHighlightedVariant: {
    type: String,
    default: "bodyXLarge",
  },
  headline: {
    type: String,
    default: undefined,
  },
  dateHeadline: {
    type: String,
    default: undefined,
  },
  subTitle: {
    type: String,
    default: undefined,
  },
  subTitleDate: {
    type: String,
    default: undefined,
  },
  subTitleIcon: {
    type: String,
    default: undefined,
  },
  subTitleDateIcon: {
    type: String,
    default: undefined,
  },
  isNewTab: {
    type: Boolean,
    default: false,
  },
  buttonIconName: {
    type: String,
    default: undefined,
  },
  buttonText: {
    type: String,
    default: null,
  },
  url: {
    type: String,
    default: undefined,
  },
  isButtonDisabled: {
    type: Boolean,
    default: false,
  },
  isButtonUnderline: {
    type: Boolean,
    default: false,
  },
  buttonVariant: {
    type: String as PropType<"primary" | "secondary" | "ghost" | undefined>,
    default: "ghost",
  },
  tags: {
    type: Object as PropType<{
      data: {
        attributes: {
          backgroundColor?: tagBackground;
          href?: string;
          text?: string;
          titleColor?: tagColors;
          variant?: tagVariants;
        };
      }[];
    }>,
    default: () => {},
  },
  materialFile: {
    type: Object as PropType<{ data: {} }>,
    default: () => {},
  },
  buttonCustomStyle: {
    type: String,
    default: undefined,
  },
  subTitleClassName: {
    type: String,
    default: undefined,
  },
});
const dynamicIconName = ref(props.buttonIconName);
const dynamicText = ref(props.buttonText);
const flagColorSucesfuly = ref(false);
const { strapiBaseUrl } = useRuntimeConfig().public;

const handleNavigation = () => {
  if (!props.url) return;

  if (props.url.includes("/uploads")) {
    downloadPdf();
    return;
  }

  if (props.url.startsWith("http") || props.isNewTab) {
    window.open(props.url, "_blank");
    return;
  }

  navigateTo(props.url, { external: false });
};

const downloadPdf = async () => {
  try {
    const url = props.url?.startsWith("https")
      ? props.url
      : strapiBaseUrl + props.url;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Failed to fetch PDF");
    }
    flagColorSucesfuly.value = true;
    dynamicIconName.value = "check_circle";
    dynamicText.value = "downloaded";

    const link = document.createElement("a");
    link.href = url;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error downloading PDF:", error);
    // Handle error as needed
  }
};
</script>
